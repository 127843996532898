<template>
  <div class="energyguard">
    <!-- 背景 -->
    <div class="EMS_bg">
      <div class="EMS_bg_top">
        <the-nav></the-nav>
      </div>
      <div class="EMS_bg_bottom w1200">
        <div class="EMS_bg_bottom_left">
          <div class="EMS_bg_bottom_logo">
            <img src="../../assets/imgs/img-logo-b.png" alt="储能管家" />
          </div>
          <h1>管理多电站的数字资产平台</h1>
          <h6>电站资产快速上云，管好用好才是硬道理</h6>
          <div class="yuyue_btn" @click="booking">预约体验</div>
        </div>
        <div class="EMS_bg_bottom_right"></div>
      </div>
    </div>
    <!-- 1 -->
    <div class="EMS_box bg_white">
      <div class="EMS_box_con1 w1200">
        <div class="left">
          <img
            src="../../assets/imgs/img-guard-picture-a.png"
            alt="工单闭环管理"
          />
        </div>
        <div class="right">
          <div class="common_title">
            <span class="cn_title">工单闭环管理</span>
          </div>
          <div class="right_description">
            全类工单管理：故障工单，巡检工单，检修/保养工单。A类故障自动转工单，并与APP联动，形成闭环管理。
            工单统计分析，响应速度，处理效率，排名统计一目了然。
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="EMS_box bg_image">
      <div class="EMS_box_con1 w1200">
        <div class="right" style="margin-right: 108px">
          <div class="common_title">
            <span class="cn_title" style="color: #fff">故障智能定位</span>
          </div>
          <div
            class="right_description"
            style="color: rgba(255, 255, 255, 0.85)"
          >
            智能合并多设备联生故障告警，快速定位核心故障，直达问题要点。
          </div>
        </div>
        <div class="left" style="margin-right: 0">
          <img
            src="../../assets/imgs/img-guard-picture-b.png"
            alt="故障智能定位"
          />
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="EMS_box bg_white">
      <div class="EMS_box_con1 w1200">
        <div class="left">
          <img src="../../assets/imgs/img-guard-picture-c.png" alt="三维可视" />
        </div>
        <div class="right">
          <div class="common_title">
            <span class="cn_title">三维可视 透彻入里</span>
          </div>
          <div class="right_description">叠加空间信息 极大提升运维效率</div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="EMS_box bg_gray" style="padding-bottom: 120px">
      <div class="EMS_box_con4 w1200">
        <div class="common_title">
          <span class="cn_title">从粗放运维到精细运维 轻松升级</span>
          <span class="en_title">upgrade</span>
        </div>
        <div class="grid">
          <div
            class="grid_item bg_white"
            v-for="(item, index) in productList"
            :key="'pro' + index"
          >
            <svg class="left" aria-hidden="true">
              <use :xlink:href="`#${item.svg}`"></use>
            </svg>
            <div class="right">
              <div class="right_title">{{ item.name }}</div>
              <div class="right_desc">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div
      class="EMS_box bg_white"
      style="padding-top: 120px; padding-bottom: 144px"
    >
      <div class="EMS_box_con5 w1200">
        <div class="common_title align_center">
          <span class="cn_title">请一个好管家，资产管理更放心</span>
          <span class="en_title">housekeeper</span>
        </div>
        <div class="grid">
          <div
            class="grid_item bg_white"
            v-for="(item, index) in valueList"
            :key="'value' + index"
          >
            <svg class="grid_item_img" aria-hidden="true">
              <use :xlink:href="`#${item.svg}`"></use>
            </svg>
            <div class="grid_item_title">{{ item.name }}</div>
            <div class="grid_item_desc">
              <span>{{
                item.description.substring(0, item.description.indexOf(" "))
              }}</span>
              <span>{{
                item.description.substring(item.description.indexOf(" "))
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="EMS_box bg_gray" style="padding: 0; overflow: hidden">
      <div class="EMS_box_con6 w1200">
        <div class="common_title">
          <span class="cn_title" style="text-align: left">
            业务覆盖 <br />
            中国、德国、新加坡多个国家
          </span>
          <span class="en_title">scope of business</span>
        </div>
        <div class="EMS_box_con6_bottom">
          <div class="EMS_box_con6_bottom_item">
            <svg class="svg" aria-hidden="true">
              <use :xlink:href="`#a-icon-guard-fill-map-a1x`"></use>
            </svg>
            <div class="right">
              <span>30+</span>
              <span>电站</span>
            </div>
          </div>
          <div class="EMS_box_con6_bottom_item">
            <svg class="svg" aria-hidden="true">
              <use :xlink:href="`#a-icon-guard-fill-map-b1x`"></use>
            </svg>
            <div class="right">
              <span>500+MWh</span>
              <span>装机总量</span>
            </div>
          </div>
          <div class="EMS_box_con6_bottom_item">
            <svg class="svg" aria-hidden="true">
              <use :xlink:href="`#a-icon-guard-fill-map-c1x`"></use>
            </svg>
            <div class="right">
              <span>70,000+</span>
              <span>纯碳减排</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <the-footer></the-footer>
  </div>
</template>

<script>
export default {
  name: "energyguard",
  data() {
    return {
      // 产品特点
      productList: [
        {
          name: "运维模板",
          description:
            "内置国家标准模板包括巡检、保养，并可自定义设置，批量下发。",
          svg: "a-icon-guard-line-a1x",
        },
        {
          name: "故障处理图谱",
          description:
            "结合多年实地运维经验，为故障处理提供知识图谱，提升消缺效率。",
          svg: "a-icon-guard-line-b1x",
        },
        {
          name: "电费结算",
          description:
            "打通结算支付通路，用电方可预充现扣，提升多方财务效率，减少拖欠。",
          svg: "a-icon-guard-line-c1x",
        },
        {
          name: "设备二维码",
          description:
            "实现设备级电子档案管理，便于运维上报，调取设备的运维记录。",
          svg: "a-icon-guard-line-d1x",
        },
        {
          name: "统计报表",
          description:
            "工单效率，人员绩效，损失收益，运维管理从主观随意到有理有据。",
          svg: "a-icon-guard-line-e1x",
        },
        {
          name: "运维专家托管",
          description:
            "核心运维托管给专家，降低现场人员要求，更低费用实现高水平运维。",
          svg: "a-icon-guard-line-f1x",
        },
      ],
      // 价值
      valueList: [
        {
          name: "升级",
          description: "摒弃纸笔表格 信息化赋能助力",
          svg: "a-icon-guard-fill-a1x",
        },
        {
          name: "降本",
          description: "更精简的团队 更好的运维表现",
          svg: "a-icon-guard-fill-b1x",
        },
        {
          name: "增收",
          description: "缩短故障时长 更多放电和收益",
          svg: "a-icon-guard-fill-c1x",
        },
        {
          name: "沉淀",
          description: "数据价值沉淀 经验得以传承",
          svg: "a-icon-guard-fill-d1x",
        },
      ],
    };
  },
  methods: {
    booking() {
      // this.$store.commit("setState", { visible: true });
      window.open(
        `https://www.fqixin.cn/fsh5/smart-form/index.html?cardId=6570209edf8ff0034dc6d51a`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
.energyguard {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .EMS_bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 642px;
    background-color: #090c1b;
    // background-image: url("../../assets/imgs/img-home-bg.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%;
    &_top {
      display: flex;
      justify-content: center;
      height: 80px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
    }
    &_bottom {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-top: 48px;
      &_logo {
        width: 183px;
        height: 72px;
        margin-top: 48px;
        margin-bottom: 32px;
      }
      h1 {
        font-size: 40px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        margin-bottom: 16px;
      }
      h6 {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: rgba(255, 255, 255, 0.45);
        margin-bottom: 80px;
      }
      .yuyue_btn {
        width: 187px;
        height: 56px;
        line-height: 56px;
        background-color: rgba(0, 198, 153, 0.15);
        border: 1px solid rgba(0, 198, 153, 0.45);
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #00c699;
        cursor: pointer;
        &:hover {
          background-color: #00c699;
          color: #ffffff;
        }
      }
      &_right {
        width: 697px;
        height: 440px;
        background-image: url("../../assets/imgs/img-product-b.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  .bg_white {
    background-color: #ffffff;
  }
  .bg_gray {
    background-color: #f4f5f7;
  }
  .bg_image {
    background-image: url("../../assets/imgs/img-ph-blue-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .EMS_box {
    padding: 88px 0;
    .common_title {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 34px;
      .cn_title {
        font-size: 30px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .en_title {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
        margin-top: 8px;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        content: "";
        display: block;
        width: 210px;
        height: 2px;
        background-color: #e6e6e6;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        content: "";
        display: block;
        width: 48px;
        height: 2px;
        background-color: #00c699;
      }
    }
    .align_center {
      align-items: center;
      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &_con1 {
      display: flex;
      justify-content: center;
      .left {
        width: 496px;
        height: 660px;
        margin-right: 108px;
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 394px;
        margin-top: 146px;
        &_description {
          margin-top: 40px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    &_con3 {
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 248px);
        grid-gap: 8px 8px;
        margin-top: 48px;
        &_item {
          display: flex;
          background-color: #ffffff;
          border-radius: 4px;
          padding: 16px 0;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 144px;
            border-right: 1px solid #e6e6e6;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            .hr {
              width: 16px;
              height: 2px;
              background-color: #00c699;
              margin-top: 16px;
            }
          }
          .right {
            flex: 1;
          }
        }
      }
    }
    &_con4 {
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 166px);
        grid-gap: 8px 8px;
        margin-top: 48px;
        &_item {
          display: flex;
          padding: 24px 32px;
          .left {
            width: 56px;
            height: 56px;
            margin-top: 24px;
            margin-right: 32px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            &_title {
              padding-bottom: 16px;
              border-bottom: 1px solid #e6e6e6;
              font-size: 16px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 600;
              text-align: left;
              color: rgba(0, 0, 0, 0.85);
            }
            &_desc {
              margin-top: 16px;
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
    &_con5 {
      .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 236px;
        grid-column-gap: 8px;
        margin-top: 48px;
        &_item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #f4f5f7;
          border-radius: 4px;
          &_img {
            width: 72px;
            height: 72px;
            margin-bottom: 16px;
          }
          &_title {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 8px;
          }
          &_desc {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
    &_con6 {
      box-sizing: border-box;
      padding: 88px 0 48px 0;
      background-image: url("../../assets/imgs/img-guard-earth.png");
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 1680px 100%;
      &_bottom {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 64px;
        align-content: center;
        width: 100%;
        height: 161px;
        background: rgba(255, 255, 255, 0.85);
        border: 2px solid rgba(255, 255, 255, 0.85);
        border-radius: 4px;
        margin-top: 241px;
        &_item {
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid rgba(0, 0, 0, 0.08);
          &:last-child {
            border-right: none;
          }
          .svg {
            width: 56px;
            height: 56px;
            margin-right: 24px;
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            > span:first-child {
              font-size: 30px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              text-align: left;
              color: #00c699;
            }
          }
        }
      }
    }
  }
}
</style>
